import axios from 'axios';
import { $observable, bodyHeader } from '../common/libs/AxiosObservable';

export const login = ({ username, password }) => {
  let params = JSON.stringify({
    username,
    password,
  });

  const data = $observable(
    axios.post('api/auth/sign-in', params, { headers: bodyHeader })
  );
  return data;
};

export const signUp = ({ username, password }) => {
  let params = JSON.stringify({
    username,
    password,
  });

  return $observable(
    axios.post('api/auth/sign-up', params, { headers: bodyHeader })
  );
};
export const requestRefreshToken = ({ accessToken, refreshToken }) => {
  let params = JSON.stringify({
    accessToken,
    refreshToken,
  });

  return axios.post('api/auth/token-refresh', params, {
    headers: bodyHeader,
  });
};

export const verifyTwoFactor = ({ session, code }) => {
  let params = JSON.stringify({
    session,
    code,
  });

  return $observable(
    axios.post('api/auth/auth-two-factor', params, { headers: bodyHeader })
  );
};
