// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// react-countup component
import CountUp from 'react-countup';

// Material Kit 2 React components
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';

function DefaultCounterCard({ color, count, title, description, ...rest }) {
  return (
    <YCBox p={2} textAlign='center' lineHeight={1}>
      <YCTypography variant='h1' color={color} textGradient>
        <CountUp end={count} duration={1} {...rest} />
      </YCTypography>
      {title && (
        <YCTypography variant='h5' mt={2} mb={1}>
          {title}
        </YCTypography>
      )}
      {description && (
        <YCTypography variant='body2' color='text'>
          {description}
        </YCTypography>
      )}
    </YCBox>
  );
}

// Setting default props for the DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: 'info',
  description: '',
  title: '',
};

// Typechecking props for the DefaultCounterCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DefaultCounterCard;
