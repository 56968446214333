// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import YCBox from 'components/YCBox';

// Images
import DefaultCounterCard from 'examples/Cards/CounterCards';

function ProfileCounter({ historyData = [] }) {
  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   return `${minutes} `;
  // };
  return (
    <YCBox component='section' pt={3} pb={8}>
      <Container>
        <Grid container justifyContent='center' sx={{ textAlign: 'center' }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={historyData.length}
              separator=','
              title='Số bài thi'
              description='Số lượng bai thi mà bạn tham gia.'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={historyData?.reduce((pre, cur) => pre + cur.duration, 0)}
              separator=','
              title='Số thời gian làm bài (Phút)'
              description='Tổng số thời gian mà bạn thực hiện bài thi'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={historyData?.reduce(
                (pre, cur) =>
                  pre +
                  cur?.UserAnswers.filter((e) => e.is_correct === 1).length,
                0
              )}
              suffix={
                '/' +
                historyData?.reduce(
                  (pre, cur) => pre + cur?.Exam?.total_question,
                  0
                )
              }
              title='Số câu đúng'
              description='Số câu trả lời đúng trên tổng câu hỏi mà bạn thực hiện.'
            />
          </Grid>
        </Grid>
      </Container>
    </YCBox>
  );
}

export default ProfileCounter;
