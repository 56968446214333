// @mui material components
import Card from '@mui/material/Card';
// YC React components
import YCBox from 'components/YCBox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import YCButton from 'components/YCButton';
// Images
import bgImage from 'assets/images/footer-bg.jpeg';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ExamGridCells from 'components/YCExams/ExamGridCell';
import ExamTimer from 'components/YCExams/ExamTimer';
import ExamQuestion from 'components/YCExams/ExamQuestion';
import ExamConfirmationPopup from 'components/YCExams/ExamConfirmationPopup';
import { getDetail } from 'apis/examService';
import { startExam } from 'apis/examService';
import { submitExam } from 'apis/examService';
import { useSearchParams } from 'react-router-dom';
import { setCookie, getCookie } from 'common/CookieUtils';

function ExamBasic() {
  const [searchParams] = useSearchParams();
  const exam_id = searchParams.get('exam_id');

  const [totalPages, setTotalPages] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCell, setSelectedCell] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(totalPages).fill(null)
  );
  const [questions, setQuestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [examDetail, setDetailExam] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [examParticipant, setUserParticipant] = useState(null);

  const handleSubmit = async () => {
    setIsSubmitted(true);

    const payloadData = {
      id: examParticipant.id,
      exam_id: examDetail.id,
      duration: examDetail.duration - timeLeft / 60,
      ExamAnswers: selectedAnswers.filter((e) => e),
    };

    let page = `/results?exam_id=${examDetail.id}`;
    const guest_identifier = getCookie('guest_identifier');
    if (guest_identifier) {
      payloadData.guest_identifier = guest_identifier;
      page = page + `&guest_identifier=${guest_identifier}`;
    }
    submitExam(payloadData).subscribe({
      next() {
        navigate(page);
      },
      error(error) {
        console.log(error);
      },
    });
  };
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Ngăn chặn hành động mặc định của form
    setShowPopup(true); // Hiển thị popup xác nhận
  };

  const handleConfirmReload = () => {
    handleSubmit();
  };

  const handleCancelReload = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    if (!isStarted) return;
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      setShowPopup(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isStarted]);

  useEffect(() => {
    getDetail(exam_id).subscribe({
      next({ data }) {
        const resultData = data.data;
        const ExamQuestions = resultData.ExamQuestions || [];
        setDetailExam(resultData);
        setQuestions(ExamQuestions);
        setTotalPages(ExamQuestions.length);
        setTimeLeft(resultData.duration * 60);
        setSelectedAnswers(Array(ExamQuestions.length).fill(null));
      },
      error(error) {
        console.log(error);
      },
    });
  }, [exam_id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isStarted) return;
    if (isStarted && timeLeft <= 0) {
      handleSubmit();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isStarted, timeLeft]);

  const handleAnswerSelect = (questionIndex, answerId) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = {
      exam_question_id: questions[questionIndex].id,
      selected_answer_id: answerId,
    };
    setSelectedAnswers(updatedAnswers);
  };

  const handleCellSelect = (index) => {
    setSelectedCell(index);
    setCurrentPage(index + 1);
  };

  const handleNavigatePage = (direction) => {
    const newPage = currentPage + direction;
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setSelectedCell(newPage - 1); // cập nhật selectedCell tương ứng với currentPage
    }
  };

  const handleStart = async () => {
    const guest_identifier = getCookie('guest_identifier');
    startExam({
      exam_id: examDetail.id,
      guest_identifier: guest_identifier,
    }).subscribe({
      next({ data }) {
        setUserParticipant(data?.data);
        if (!guest_identifier) {
          const guestIdentifier = data?.data.guest_identifier;
          if (guestIdentifier) setCookie('guest_identifier', guestIdentifier);
        }

        setIsStarted(true);
      },
      error(error) {
        console.log(error);
      },
    });
  };

  return (
    <>
      <YCBox bgColor='yellow'>
        <YCBox
          minHeight='5rem'
          width='100%'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'grid',
            placeItems: 'center',
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: timeLeft <= 300 ? '2px solid red' : 'none',
          }}
        >
          <YCBox component='section' py={{ xs: 6, sm: 12 }}>
            <Container backgroundColor='yellow'>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                mx='auto'
                component='form'
                role='form'
                onSubmit={handleFormSubmit}
              >
                <Grid
                  xs={12}
                  sm={5}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{
                    width: 'auto', // Chiều rộng tự động theo nội dung
                    maxWidth: '100%', // Đảm bảo không vượt quá chiều rộng màn hình
                  }}
                >
                  {!isStarted ? (
                    <YCBox
                      pt={2}
                      pb={2}
                      px={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <h2>Thời lượng làm bài: {timeLeft / 60} Phút.</h2>
                      <YCButton
                        variant='gradient'
                        color='success'
                        onClick={handleStart}
                      >
                        Bắt đầu thi
                      </YCButton>
                    </YCBox>
                  ) : (
                    <ExamTimer timeLeft={timeLeft} is_start={isStarted} />
                  )}
                </Grid>
                <Grid container justifyContent='center' py={6}>
                  <Grid
                    xs={12}
                    sm={5}
                    md={8}
                    lg={8}
                    xl={8}
                    // sx={{ minHeight: '500px' }}
                  >
                    <YCBox pt={2} pb={2} px={2}>
                      {selectedCell !== null && questions[selectedCell] ? (
                        <ExamQuestion
                          question={questions[selectedCell]}
                          selectedAnswer={selectedAnswers[selectedCell]}
                          handleAnswerSelect={(answerId) =>
                            handleAnswerSelect(selectedCell, answerId)
                          }
                          totalCells={totalPages}
                          selectedCell={selectedCell}
                        />
                      ) : (
                        <Typography variant='h5'>
                          Vui lòng chọn một ô
                        </Typography>
                      )}
                      <YCBox mt={4} mb={1} textAlign='center'>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(-1)}
                          disabled={currentPage === 1}
                          sx={{ mx: 1 }}
                        >
                          Quay lại
                        </YCButton>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(1)}
                          disabled={currentPage === totalPages || !isStarted}
                          sx={{ mx: 1 }}
                        >
                          Tiếp theo
                        </YCButton>
                      </YCBox>
                    </YCBox>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                    <Card sx={{ padding: 2 }} disabled={!isStarted}>
                      <Typography variant='h6' align='center' marginBottom={3}>
                        Bảng Các Ô
                      </Typography>
                      <ExamGridCells
                        totalCells={totalPages}
                        selectedCell={selectedCell}
                        selectedAnswers={selectedAnswers}
                        handlePageChange={(_, value) =>
                          handleCellSelect(value - 1)
                        }
                        isStarted={isStarted}
                      />
                      <YCBox sx={{ padding: 7 }}>
                        <YCButton
                          variant='gradient'
                          color='success'
                          fullWidth
                          type='submit'
                          onClick={() => setShowPopup(true)}
                          disabled={isSubmitted || !isStarted}
                        >
                          Nộp Bài
                        </YCButton>
                      </YCBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </YCBox>
        </Card>

        <ExamConfirmationPopup
          showPopup={showPopup}
          onClose={handleCancelReload}
          onConfirm={handleConfirmReload}
        />
        {/* <Footer /> */}
      </YCBox>
    </>
  );
}

export default ExamBasic;
