import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { removeCookie } from 'common/CookieUtils';

function LogOut() {
  const navigate = useNavigate();

  useEffect(() => {
    removeCookie('refreshToken');
    removeCookie('accessToken');
    // Optional delay to ensure cookies are removed
    setTimeout(() => {
      navigate('/authentication/sign-in');
    }, 1000); // Adjust delay as needed
  }, [navigate]);

  return null; // No content to render in this component
}

export default LogOut;
