import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import YCBox from 'components/YCBox';
import YCAvatar from 'components/YCAvatar';
import YCButton from 'components/YCButton';
import YCTypography from 'components/YCTypography';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import profilePicture from 'assets/images/luce.jpg';
import bgImage from 'assets/images/bg2.jpg';
import routes from 'routes';
import ProfileCounter from './ProfileCounter';
import FormEdit from './FormEdit';
import Footer from '../Footer';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';

import { Visibility } from '@mui/icons-material';
import { profile } from 'apis/userService';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    profile().subscribe({
      next({ data }) {
        const resultData = data.data;
        setUsername(resultData?.username);
        setEmail(resultData?.Candidate?.email);
        setName(resultData?.Candidate?.name);
        setHistoryData(resultData?.ExamParticipants);
      },
      error(error) {
        console.log(error);
      },
    });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleViewDetails = (exam_id) => {
    navigate(`/results?exam_id=${exam_id}`);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   return `${minutes} `;
  // };
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <YCBox bgColor='#3E3F46'>
        <YCBox
          minHeight='15rem'
          width='100%'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'grid',
            placeItems: 'center',
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <YCBox component='section' py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                mx='auto'
                component='form'
                role='form'
              >
                <Grid item xs={12} md={7} mx={{ xs: 'auto', sm: 6, md: 1 }}>
                  <YCBox mt={{ xs: -16, md: -20 }}>
                    <YCAvatar
                      src={profilePicture}
                      alt='Burce Mars'
                      size='xxl'
                      shadow='xl'
                    />
                  </YCBox>
                  <YCBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mb={1}
                  >
                    <YCTypography variant='h3'>{name}</YCTypography>
                    <YCButton
                      variant='outlined'
                      color='info'
                      size='small'
                      onClick={handleClickOpen}
                    >
                      Edit
                    </YCButton>
                  </YCBox>
                </Grid>
                <ProfileCounter historyData={historyData} />
              </Grid>

              <YCBox
                component='section'
                variant='solid' // (nếu cần)
                fullWidth // Làm thẻ rộng full nếu YCBox hỗ trợ prop này
                bgColor='dark'
                borderRadius='16px' // Làm tròn góc
                py={6}
                px={{ xs: 2, lg: 0 }}
              >
                <Container>
                  {/* <Grid item> */}
                  <YCTypography variant='h3' color='white'>
                    Lịch sử làm bài thi:
                  </YCTypography>
                  {/* </Grid> */}
                  <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                    {historyData.length > 0 ? (
                      <Table stickyHeader>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {' '}
                              <h5>Bài thi số</h5>
                            </TableCell>
                            <TableCell>
                              {' '}
                              <h5>Đáp án đúng</h5>
                            </TableCell>
                            <TableCell>
                              {' '}
                              <h5>Thời gian (Phút)</h5>
                            </TableCell>
                            <TableCell>
                              {' '}
                              <h5>Điểm</h5>
                            </TableCell>
                            <TableCell>
                              {' '}
                              <h5>Thao tác</h5>
                            </TableCell>
                          </TableRow>
                          {historyData?.map((row, index) => (
                            <TableRow>
                              <TableCell> Exam {index + 1}</TableCell>
                              <TableCell>
                                {row.UserAnswers.filter(
                                  (e) => e.is_correct === 1
                                ).length || 0}
                                /{row.Exam.total_question}
                              </TableCell>
                              <TableCell>
                                {row.duration || 0}/{row.Exam.duration}
                              </TableCell>
                              <TableCell>{row.score || 0}</TableCell>
                              <TableCell>
                                <IconButton
                                  color='primary'
                                  onClick={() => handleViewDetails(row.id)}
                                >
                                  <Visibility />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <YCTypography variant='h3' color='white'>
                        Bạn chưa có lịch sử!
                      </YCTypography>
                    )}
                  </TableContainer>
                </Container>
              </YCBox>
            </Container>
          </YCBox>
        </Card>

        <FormEdit
          open={open}
          handleClose={handleClose}
          username={username}
          email={email}
          name={name}
          setUsername={setUsername}
          setEmail={setEmail}
          setName={setName}
          setOpen={setOpen}
        />
        <Footer bgColor={'white'} />
      </YCBox>
    </>
  );
}

export default Profile;
