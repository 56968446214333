/* eslint-disable react/prop-types */
import React from 'react';
import YCBox from 'components/YCBox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ExamQuestionResult({ question, totalCells, selectedCell }) {
  /**
   * đáp án chọn đúng => hiện thị màu xanh
   * đáp án chọn sai => hiển thị màu đỏ item được chọn. và màu xanh đối với item đúng
   * không chọn đáp án nào => hiển thị màu vàng đối với đáp án đúng.
   * @param {*} answer
   * @returns
   */
  const handleCellSelect = (answer) => {
    // answer là từng item câu trả lời A, B, C,D
    if (answer?.UserAnswers?.length === 0) {
      if (answer?.is_correct) {
        // tìm trong câu hỏi có đáp án nào được chọn không
        const userChoose = question.ExamAnswers.find(
          (e) => e.UserAnswers.length > 0
        );
        if (userChoose) return '#6fbf73'; // màu xanh
        return 'yellow'; // màu vàng
      } else return 'white.main';
    } else {
      if (!answer.is_correct) return '#ff784e'; // màu đỏ
      else return '#6fbf73'; // màu xanh
    }
  };

  return (
    <>
      <Typography variant='h4'>
        Câu hỏi {selectedCell + 1}/{totalCells}:
      </Typography>
      <Typography variant='h5'>{question?.content}</Typography>
      <YCBox mt={2}>
        {question?.ExamAnswers?.sort((a, b) => a.order - b.order).map(
          (answer, index) => (
            <Button
              key={index}
              variant='contained'
              fullWidth
              disableElevation
              sx={{
                mt: 1,
                backgroundColor: handleCellSelect(answer) || 'white.main',
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'flex-start',
                contrastText: '#ffffff',
              }}
            >
              <Box sx={{ mr: 1 }}>
                <Typography variant='h5'>{answer?.code_option}.</Typography>
              </Box>
              {answer?.content}
            </Button>
          )
        )}
      </YCBox>
    </>
  );
}

export default ExamQuestionResult;
