/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

function ExamGridCellResult({
  totalCells,
  selectedCell,
  selectedAnswers,
  handlePageChange,
  examQuestions,
}) {
  const handleBackGroundColor = (question) => {
    let bgColor = 'blue';
    const answer = question.ExamAnswers.find((e) => e.UserAnswers.length > 0);
    if (!answer) bgColor = '#ffea00'; // màu vàng
    else if (answer.is_correct) bgColor = '#6fbf73'; // màu xanh
    else bgColor = '#ff784e'; //màu đỏ
    return bgColor;
  };
  const createGridCellsResult = () => {
    const gridCells = [];
    for (let i = 0; i < totalCells; i++) {
      const bgColor = handleBackGroundColor(examQuestions[i]);
      gridCells.push(
        <Paper
          key={i}
          elevation={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            width: 50,
            margin: 0.5,
            cursor: 'pointer',
            backgroundColor: bgColor,

            color: selectedAnswers[i] !== null ? 'white' : 'black',
          }}
          onClick={() => handlePageChange(null, i + 1)}
        >
          {i + 1}
        </Paper>
      );
    }
    return gridCells;
  };

  return (
    <Grid container justifyContent='center'>
      {createGridCellsResult()}
    </Grid>
  );
}

export default ExamGridCellResult;
