import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';
import YCInput from 'components/YCInput';
import YCButton from 'components/YCButton';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import SimpleFooter from 'examples/Footers/SimpleFooter';
import routes from 'routes';
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { login } from 'apis/authService';
import { setCookie, getCookie } from 'common/CookieUtils';
import { removeCookie } from 'common/CookieUtils';

function SignInBasic() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(
    getCookie('rememberMe') == 'true'
  );

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    let accessToken = getCookie('accessToken') ?? '';
    if (accessToken !== '') {
      setTimeout(() => {
        navigate('/authentication/profile');
      }, 100);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password }).subscribe({
      next({ data }) {
        const { accessToken, refreshToken } = data.data;
        setCookie('accessToken', accessToken);
        setCookie('refreshToken', refreshToken);
        setCookie('rememberMe', rememberMe);
        removeCookie('guest_identifier');
        navigate('/authentication/profile');
      },
      error(error) {
        console.log(error);
        alert('Đăng nhập thất bại');
      },
    });
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <YCBox
        position='absolute'
        top={0}
        left={0}
        zIndex={1}
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <YCBox
        px={1}
        width='100%'
        height='100vh'
        mx='auto'
        position='relative'
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <YCBox
                variant='gradient'
                bgColor='info'
                borderRadius='lg'
                coloredShadow='info'
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign='center'
              >
                <YCTypography
                  variant='h4'
                  fontWeight='medium'
                  color='white'
                  mt={1}
                >
                  Sign in
                </YCTypography>
                <Grid
                  container
                  spacing={3}
                  justifyContent='center'
                  sx={{ mt: 1, mb: 2 }}
                >
                  <Grid item xs={2}>
                    <YCTypography
                      component={MuiLink}
                      href='#'
                      variant='body1'
                      color='white'
                    >
                      <FacebookIcon color='inherit' />
                    </YCTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <YCTypography
                      component={MuiLink}
                      href='#'
                      variant='body1'
                      color='white'
                    >
                      <GitHubIcon color='inherit' />
                    </YCTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <YCTypography
                      component={MuiLink}
                      href='#'
                      variant='body1'
                      color='white'
                    >
                      <GoogleIcon color='inherit' />
                    </YCTypography>
                  </Grid>
                </Grid>
              </YCBox>
              <YCBox pt={4} pb={3} px={3}>
                <YCBox component='form' role='form' onSubmit={handleSubmit}>
                  <YCBox mb={2}>
                    <YCInput
                      type='text'
                      label='Username'
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </YCBox>
                  <YCBox mb={2}>
                    <YCInput
                      type='password'
                      label='Password'
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </YCBox>
                  <YCBox display='flex' alignItems='center' ml={-1}>
                    <Switch
                      checked={rememberMe}
                      onChange={handleSetRememberMe}
                    />
                    <YCTypography
                      variant='button'
                      fontWeight='regular'
                      color='text'
                      onClick={handleSetRememberMe}
                      sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </YCTypography>
                  </YCBox>
                  <YCBox mt={4} mb={1}>
                    <YCButton
                      variant='gradient'
                      color='info'
                      fullWidth
                      type='submit'
                    >
                      sign in
                    </YCButton>
                  </YCBox>
                  <YCBox mt={3} mb={1} textAlign='center'>
                    <YCTypography variant='button' color='text'>
                      Don&apos;t have an account?{' '}
                      <YCTypography
                        component={Link}
                        to='/authentication/sign-up'
                        variant='button'
                        color='info'
                        fontWeight='medium'
                        textGradient
                      >
                        Sign up
                      </YCTypography>
                    </YCTypography>
                  </YCBox>
                </YCBox>
              </YCBox>
            </Card>
          </Grid>
        </Grid>
      </YCBox>
      <YCBox width='100%' position='absolute' zIndex={2} bottom='1.625rem'>
        <SimpleFooter light />
      </YCBox>
    </>
  );
}

export default SignInBasic;
