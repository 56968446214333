// @mui material components
// import Icon from '@mui/material/Icon';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ExamsCreate from 'pages/Exams/CreateExam';
import LogOut from 'pages/LandingPages/Profile/logout';
import Icon from '@mui/material/Icon';
import SignInBasic from 'pages/LandingPages/SignIn';
import Profile from 'pages/LandingPages/Profile';
import { getCookie } from 'common/CookieUtils';

const routes = [
  // {
  //   name: 'pages',
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       name: 'landing pages',
  //       collapse: [
  //         {
  //           name: 'about us',
  //           route: '/pages/landing-pages/about-us',
  //           component: <AboutUs />,
  //         },
  //         {
  //           name: 'contact us',
  //           route: '/pages/landing-pages/contact-us',
  //           component: <ContactUs />,
  //         },
  //         {
  //           name: 'author',
  //           route: '/pages/landing-pages/author',
  //           component: <Author />,
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    route: '/page/exams/CreateExam',
    name: 'Luyện Thi',
    key: 'exam',
    component: <ExamsCreate />,
    columns: 1,
    icon: (
      <SelfImprovementOutlinedIcon>
        SelfImprovementOutlinedIcon
      </SelfImprovementOutlinedIcon>
    ),
  },

  {
    type: 'collapse',
    name: 'Sign In',
    key: 'sign-in',
    hide: true,
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/authentication/sign-in',
    component: <SignInBasic />,
  },
  {
    name: 'account',
    rowsPerColumn: 2,
    key: 'account',
    columns: 1,
    icon: <AccountCircleIcon>AccountCircleIcon</AccountCircleIcon>,
    collapse: [
      {
        name: 'Profile',
        collapse: [
          {
            name: 'my profile',
            route: '/pages/authentication/profile',
            component: <Profile />,
          },
          {
            name: 'log out',
            route: '/pages/authentication/profile/logout',
            component: <LogOut />,
          },
        ],
      },
    ],
  },
];
// function getFilteredRoutes() {
//   const accessToken = getCookie('accessToken');
//   return routes.filter((route) =>
//     accessToken ? route.key !== 'sign-in' : route.key !== 'account'
//   );
// }

// export default getFilteredRoutes();
export default routes;
