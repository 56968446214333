// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// Material Kit 2 React components
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';

// Material Kit 2 React base styles
import typography from 'assets/theme/base/typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
function SimpleFooter({ company, light }) {
  //links
  const { href, name } = company;
  const { size } = typography;
  return (
    <Container>
      <YCBox
        width='100%'
        display='flex'
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <YCBox
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexWrap='wrap'
          color={light ? 'white' : 'text'}
          fontSize={size.sm}
        >
          &copy; {new Date().getFullYear()}, made with
          <YCBox
            fontSize={size.md}
            color={light ? 'white' : 'text'}
            mb={-0.5}
            mx={0.25}
          >
            <FavoriteIcon color='inherit' fontSize='inherit'>
              favorite
            </FavoriteIcon>
          </YCBox>
          by
          <Link href={href} target='_blank'>
            <YCTypography
              variant='button'
              fontWeight='medium'
              color={light ? 'white' : 'dark'}
            >
              &nbsp;{name}&nbsp;
            </YCTypography>
          </Link>
          for a better web.
        </YCBox>
        {/* <YCBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        > */}
        {/* {renderLinks()} */}
        {/* </YCBox> */}
      </YCBox>
    </Container>
  );
}

// Setting default values for the props of SimpleFooter
SimpleFooter.defaultProps = {
  company: { name: 'Nomads - Tông Đồ IT' },
  // links: [
  //   { name: "Creative Tim" },
  //   { name: "About Us" },
  //   { name: "Blog" },
  //   { href: "https://www.creative-tim.com/license", name: "License" },
  // ],
  light: false,
};

// Typechecking props for the SimpleFooter
SimpleFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape),
  light: PropTypes.bool,
};

export default SimpleFooter;
