import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
// import { AuthProvider } from 'components/Auth/auth-context';
import { initDefault } from 'common/libs/AxiosDefaultConfig';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

initDefault();
root.render(
  <BrowserRouter>
    {/* <AuthProvider> */}
    <App />
    {/* </AuthProvider> */}
  </BrowserRouter>
);
