import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

function ExamGridCells({
  totalCells,
  selectedCell,
  selectedAnswers,
  handlePageChange,
  isStarted = false,
}) {
  const createGridCells = () => {
    const gridCells = [];
    for (let i = 0; i < totalCells; i++) {
      gridCells.push(
        <Paper
          key={i}
          elevation={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            width: 50,
            margin: 0.5,
            cursor: isStarted ? 'pointer' : 'not-allowed', // Chỉ cho phép trỏ chuột khi bắt đầu
            backgroundColor:
              selectedAnswers[i] !== null || selectedCell === i // Nếu đã chọn đáp án hoặc là ô được chọn
                ? 'yellow'
                : 'white', // Màu vàng cho ô đã chọn hoặc ô đang được chọn, còn lại màu trắng
            color: selectedAnswers[i] !== null ? 'white' : 'black', // Đổi màu chữ khi đã có đáp án
            opacity: isStarted ? 1 : 0.5, // Làm mờ nếu chưa bắt đầu
          }}
          onClick={() => {
            if (isStarted) handlePageChange(null, i + 1); // Chỉ xử lý sự kiện nếu `isStarted`
          }}
        >
          {i + 1}
        </Paper>
      );
    }
    return gridCells;
  };

  return (
    <Grid container justifyContent='center'>
      {createGridCells()}
    </Grid>
  );
}

export default ExamGridCells;
