// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import YCBox from 'components/YCBox';

// Material Kit 2 React examples
import RotatingCard from 'examples/Cards/RotatingCard';
import RotatingCardFront from 'examples/Cards/RotatingCard/RotatingCardFront';
import RotatingCardBack from 'examples/Cards/RotatingCard/RotatingCardBack';
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';

// Images
import bgFront from 'assets/images/rotating-card-bg-front.jpeg';
import bgBack from 'assets/images/rotating-card-bg-back.jpeg';

function Information() {
  return (
    <YCBox component='section' py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems='center'
          sx={{ mx: 'auto' }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: 'auto' }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon='auto_stories'
                title={<>YouCat</>}
                description='Độc giả đã biết, các soạn giả của Youcat là Đức Hồng Y
                      Schönborn, người đã có mặt trong việc soạn Sách Giáo lý Hội
                      thánh Công giáo (SGLCG) năm 1992, soạn Sách Toát yếu Giáo
                      lý Hội thánh Công giáo (TYGLHTCG) năm 2005, và ngài cũng
                      đứng đầu trong việc soạn thảo Youcat (2011), cùng với một số
                      giám mục, linh mục, giáo sư, cũng như hơn 60 bạn trẻ nam nữ,
                      tất cả làm việc với nhau trong năm năm trời.'
              />
              <RotatingCardBack
                image={bgBack}
                title='Nội dung và cách trình bày nội dung của Youcat được thực hiện cách nào?'
                description='Nội dung là đức tin lãnh nhận từ các Thánh Tông Đồ,
                được trình bày một cách tổng hợp và hữu cơ thành bốn cột trụ
                cổ điển trong SGLHTCG: Hội thánh tin gì, Hội thánh cử hành
                các mầu nhiệm thế nào, Hội thánh sống theo đời sống Chúa
                Kitô thế nào, Hội thánh cầu nguyện thế nào. '
                action={{
                  type: 'internal',
                  route: '#',
                  label: 'đọc chi tiết',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: 'auto' }}>
            <Grid container spacing={3} sx={{ mt: { xs: 0 }, p: 4 }}>
              <DefaultInfoCard
                icon='looks_one'
                title='Nguồn gốc sách Youcat?'
                description='Đức Giáo Hoàng Bênêđictô XVI thông qua các Đại Hội Giới Trẻ, ngài đã gặp gỡ một giới trẻ thế giới đang muốn tin, 
                muốn tìm đến Thiên Chúa, muốn yêu mến Chúa Kitô, nên ngài ước muốn các Giám mục trên thế giới soạn cuốn Sách Giáo Lý Hội Thánh Công Giáo cho người trẻ,
                 bằng ngôn ngữ của người trẻ, để truyền bá sứ điệp Phúc Âm trong thời đại hôm nay.'
              />
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0 }, p: 4 }}>
              <DefaultInfoCard
                icon='looks_two'
                title='Tại sao Youcat được coi là Sách Giáo Lý hiện đại nhất?'
                description={[
                  ' Vì trong sách này, Hội Thánh đã chọn cách trình bày đức tin Kitô giáo sao cho sứ điệp của Hội Thánh có thể đi vào thế giới của người trẻ.',
                  ' Sách do Tòa Thánh đứng ra soạn thảo với ngôn từ đơn giản, dễ hiểu, hấp dẫn, mới mẻ và nhất là dễ thực hành trong đời sống.',
                ]}
              />
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0 }, p: 4 }}>
              <DefaultInfoCard
                icon='looks_3'
                title='Độc đáo của Youcat là gì?'
                description='Như Đức Bênêđictô XVI đã nói: độc đáo do nội dung và do cách nó được soạn ra.'
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </YCBox>
  );
}

export default Information;
