import React from 'react';
import YCBox from 'components/YCBox';

function ExamTimer({ timeLeft, is_start = false }) {
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <YCBox mt={-5} mb={-5} textAlign='center'>
      <h2>Thời gian: {formatTime(timeLeft)}</h2>
    </YCBox>
  );
}

export default ExamTimer;
