import Card from '@mui/material/Card';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';

//  React components
import YCBox from 'components/YCBox';

//  React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';

// Presentation page sections
import Counters from 'pages/Presentation/sections/Counters';
import { useState, useEffect } from 'react';
// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';
// Images
import bgImage from 'assets/images/youcat-bg.jpeg';
import Information from 'pages/Presentation/sections/Information';
import { getData } from 'apis/homeService';

function Presentation() {
  const [countUser, setCountUser] = useState(0);
  const [countQuestion, setCountQuestion] = useState(0);
  const [countBook, setCountBook] = useState(0);

  useEffect(() => {
    getData().subscribe({
      next({ data }) {
        const resultData = data.data;
        if (resultData?.countBook?._all)
          setCountBook(resultData.countBook._all);
        if (resultData?.countQuestion?._all)
          setCountQuestion(resultData.countQuestion._all);
        if (resultData?.countUser?._all)
          setCountUser(resultData.countUser._all);
      },
      error(error) {
        console.log(error);
        alert('Đăng nhập thất bại');
      },
    });
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} />
      <YCBox
        minHeight='75vh'
        width='100%'
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          display: 'grid',
          placeItems: 'center',
        }}
      ></YCBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters
          countUser={countUser}
          countBook={countBook}
          countQuestion={countQuestion}
        />
        <Information />
        {/* <QuestionRanDom /> */}
      </Card>
      <YCBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </YCBox>
    </>
  );
}

export default Presentation;
