import axios from 'axios';
import { $observable, bodyHeader } from '../common/libs/AxiosObservable';
import { getCookie } from 'common/CookieUtils';

export const create = ({ params }) => {
  const accessToken = getCookie('accessToken');
  const endPoint = accessToken ? 'api/exam/create' : 'api/exam/create_quickly';
  const data = $observable(
    axios.post(endPoint, params, { headers: bodyHeader })
  );
  return data;
};

export const getDetail = (id) => {
  const endPoint = `api/exam/${id}/detail`;
  const data = $observable(axios.get(endPoint));
  return data;
};

export const startExam = (params) => {
  const accessToken = getCookie('accessToken');
  const endPoint = accessToken
    ? `api/exam_participant/start`
    : 'api/exam_participant/guest_start';
  const data = $observable(
    axios.post(endPoint, params, { headers: bodyHeader })
  );
  return data;
};

export const submitExam = (params) => {
  const accessToken = getCookie('accessToken');
  const endPoint = accessToken
    ? `api/exam_participant/submit`
    : 'api/exam_participant/guest_submit';
  const data = $observable(
    axios.post(endPoint, params, { headers: bodyHeader })
  );
  return data;
};

export const getResult = ({ exam_id, guest_identifier }) => {
  const accessToken = getCookie('accessToken');
  const endPoint = accessToken
    ? `api/exam/${exam_id}/result`
    : `api/exam/${exam_id}/guest_result/${guest_identifier}`;
  const data = $observable(axios.get(endPoint));
  return data;
};
